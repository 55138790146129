@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Comfortaa&family=Quicksand:wght@300;400;500;600&display=swap");

.material-symbols-rounded {
  font-variation-settings: "FILL" 0, "wght" 300, "GRAD" 100, "opsz" 48;
  user-select: none;
}

body {
  font-family: "Quicksand", sans-serif;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--mainBgColor);
}

h1,
h2,
h3,
h4,
p,
ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
  user-select: none;
  font-family: "Quicksand", sans-serif;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}
img,
input,
select,
textarea,
button {
  user-select: none;
  font-family: "Quicksand", sans-serif;
}

:root {
  /* Colors */
  --mainBgColor: #f6f6f6;
  --navMainBgColor: rgb(29, 29, 29);
  --fontMainColor: white;
  --fontSecondaryColor: rgb(56, 56, 56);
  --confirmColor: #43f1a6;
  --borderColor: rgb(221, 221, 221);
  --cancelColor: #df2525;

  /* Dimensions */
  --fullWidth: 100vw;
  --navigationHeight: 8.5vh;
  --viewPortHeight: 83vh;
  --productPillHeight: 12vh;

  /* Styling */
  --mainBorderRadius: 20px;

  /* Font sizes */
  --prdPillFontSize: 5vw;
}
