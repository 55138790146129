.cameraContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  overflow: hidden;
}

.video {
  width: 100%;
}

.closeCamera {
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background-color: transparent;
  padding: 2.5vw;
  color: var(--cancelColor);
}

/* Apply styles when the device is in portrait mode */
@media (orientation: portrait) {
  .cameraContainer {
    width: 100vw;
    height: 75vw;
  }
}

/* Apply styles when the device is in landscape mode */
@media (orientation: landscape) {
  .cameraContainer {
    width: 50vw;
    height: 100vh;
  }
}
