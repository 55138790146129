.Home {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.responseContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.camContainer {
  display: flex;
  position: relative;
  cursor: pointer;
}

.flash {
  width: 50px;
  height: 50px;
  position: absolute;
  background-color: white;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.flashActive {
  animation: flashAnimation 0.5s linear forwards;
}

@keyframes flashAnimation {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/* Apply styles when the device is in portrait mode */
@media (orientation: portrait) {
  .Home {
    flex-direction: column;
  }

  .responseContainer {
    width: 100vw;
    flex: 1;
    font-size: 50vw;
  }
}
/* Apply styles when the device is in landscape mode */
@media (orientation: landscape) {
  .Home {
    flex-direction: row;
  }

  .responseContainer {
    width: 50vw;
    height: 100vh;
    font-size: 20vw;
  }
}
